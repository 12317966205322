<!-- @format -->

<template>
  <div>
    <div ref="toolbar" class="toolbar" style="position:relative;z-index:2" />
    <div ref="editor" class="text" style="position:relative;z-index:1;height: auto;" />
  </div>
</template>

<script>
import E from 'wangeditor'

export default {
  name: 'WangEditor',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    isClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      info: null,
    }
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
        this.info = null
      }
    },
    value(value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value)
      }
    },
  },
  mounted() {
    this.initEditor()
    this.editor.txt.html(this.value)
  },
  // 销毁编辑器
  beforeDestroy() {
    this.editor.destroy()
    this.editor = null
  },
  methods: {
    initEditor() {
      // 按需进行设置
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      this.editor.config.uploadImgServer = `${process.env.VUE_APP_BASE_URL}/upload/uploadAvatar`
      // this.editor.config.uploadImgShowBase64 = true; // 是否设置base64存储图片
      this.editor.config.uploadImgHeaders = {
        token: localStorage.getItem('accessToken'),
      } // 自定义 header
      this.editor.config.uploadFileName = 'image' // 后端接受上传文件的参数名
      // this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      // this.editor.config.uploadImgMaxLength = 3; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 9 * 60 * 1000 // 设置超时时间
      // this.editor.config.showLinkImg = false; //隐藏插入网络图片的功能
      // 设置编辑区域高度为 500px
      // this.editor.config.height = 500;
      this.editor.config.colors = ['#D4237A', '#FCB91A', '#E41D32', '#8A8A8A', '#009688', '#3CCA19', '#ED4E1F', '#000000', '#eeece0', '#1c487f', '#4d80bf']
      // 配置菜单
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        // 'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        // 'code', // 插入代码
        'undo', // 撤销
        // 'redo', // 重复
        // 'fullscreen', // 全屏
        'indent', // 缩进
        'lineHeight', // 行高
        'todo', // 待办事项
        'splitLine', // 分割线
      ]

      this.editor.config.uploadImgHooks = {
        // 以下方法按需使用
        // 上传图片之前
        before(xhr) {
          console.log(xhr)
          // 上传之前的一些判断操作,可阻止图片上传
          /* return {
              prevent: true,
              msg: '需要提示给用户的错误信息'
            } */
        },
        // 图片上传并返回了结果，图片插入已成功
        success(xhr) {
          console.log('success', xhr)
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail(xhr, editor, resData) {
          console.log('fail', resData)
        },
        // 上传图片出错，一般为 http 请求的错误
        error(xhr, editor, resData) {
          console.log('error', xhr, resData)
        },
        // 上传图片超时
        timeout(xhr) {
          console.log('timeout')
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert(insertImgFn, result) {
          insertImgFn(process.env.VUE_APP_COM_URL + result.data.url)
        },
      }
      this.editor.config.onchange = html => {
        this.info = html
        this.$emit('change', this.info) // 将内容同步到父组件中
      }
      // 创建富文本编辑器
      this.editor.create()
    },
  },
}
</script>

<style lang="scss">
.toolbar {
  border: 1px solid #ccc;
  .w-e-toolbar {
    .w-e-menu {
      width: 26px;
      height: 26px;
    }
  }
}
.text {
  height: 195px;
  border: 1px solid #ccc;
}
</style>
